<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="ios hydrated container-page-header">
        <div class="page-title">
          <h1>Add Item</h1>
        </div>
      </div>

      <ion-card v-show="!loading" class="item-card">
        <ion-card-content>
          <div class="card-section">
            <div class="section-title">Name:</div>
            <div class="section-content">
              <div class="select-item" @click="itemModal()">
                <span>{{ selectedItem }}</span>
              </div>
            </div>
          </div>
          <div class="card-section">
            <div class="section-title">Section to add to:</div>
            <div class="section-content">
              <ion-select v-model="sectionId" placeholder="Select a section to add the item to..." ok-text="Select" cancel-text="Cancel" interface="action-sheet">
                <template v-for="section in sections" :key="section.id">
                  <ion-select-option :value="section.id">{{ section.name }}</ion-select-option>
                </template>
              </ion-select>
            </div>
          </div>

          <template v-if="new_items_active">
            <div class="card-section" v-if="new_item.images && new_item.images.length">
              <div class="section-title">Item Images:</div>
              <ion-grid class="taken-photos">
                <ion-row class="ion-align-items-center">
                  <ion-col size="3" :key="photo" v-for="photo in new_item.images">
                    <div class="image-container">
                      <indexed-db-img :idb-key="photo.id" />
                      <div class="icon" @click="removeImage(new_item, photo)">
                        <uil-trash-alt size="26px" />
                      </div>
                    </div>
                  </ion-col>
                  <ion-col size="3">
                    <div class="orange-circle" @click="takePicture()">
                      <uil-plus size="21px" />
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>

            <div class="card-section">
              <div class="section-title">Description:</div>
              <div class="section-content">
                <ion-textarea
                  v-model="new_item.description"
                  placeholder="Type item description"
                  autoGrow="true"
                  rows="2"
                />
              </div>
            </div>

            <h2 class="question-heading">Questions</h2>

            <template v-if="new_item.questions.length">
              <template v-for="question in new_item.questions" :key="question.id">
                <div class="card-section question-section">
                  <div class="section-content ">
                    <div>{{ current_questions[question.id] }}</div>
                    <uil-times-circle @click="removeQuestion(question.id)" size="25px" />
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <div class="no-question">
                There are no questions for this item.
              </div>
            </template>
          </template>
        </ion-card-content>
      </ion-card>
    </ion-content>

    <ion-footer>
      <ion-toolbar>
        <ion-tabs>
          <ion-router-outlet />
          <ion-tab-bar slot="bottom">
            <ion-tab-button tab="cancel-additem" color="primary" @click="cancel()">
              <uil-times size="25px" />
              <ion-label>Cancel</ion-label>
            </ion-tab-button>
            <ion-tab-button tab="additem" @click="addItem('new')">
              <uil-plus  size="25px" />
              <ion-label>Save</ion-label>
            </ion-tab-button>
            <ion-tab-button tab="addAnother" @click="addItem('another')">
              <uil-plus size="25px" />
              <ion-label>Save & Add Another</ion-label>
            </ion-tab-button>
          </ion-tab-bar>
        </ion-tabs>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonContent, IonPage, IonCard, IonCardContent, IonSelect, IonSelectOption,
  IonTextarea, IonGrid, IonRow, IonCol, IonFooter, IonToolbar,
  IonTabs, IonTabBar, IonTabButton, IonLabel, alertController, modalController, IonRouterOutlet
} from '@ionic/vue';
import { UilTimes, UilPlus, UilTimesCircle, UilTrashAlt } from '@iconscout/vue-unicons'
import ItemSelect from '@/components/modals/ItemSelect';
import ItemName from '@/components/modals/ItemName';
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import IndexedDbImg from "@/components/IndexedDbImg";
import mutatesJobsMixin from "@/mixins/mutates-jobs";

export default {
  mixins: [
    mutatesJobsMixin,
  ],
  components: {
    IndexedDbImg,
    IonContent, IonPage, IonCard, IonCardContent, IonSelect, IonSelectOption, IonTextarea, IonGrid, IonRow, IonCol, IonFooter, IonToolbar, IonTabs, IonTabBar, IonTabButton, IonLabel, IonRouterOutlet,
    UilTimes, UilPlus, UilTimesCircle, UilTrashAlt
  },
  data () {
    return {
      itemId: null,
      sectionId: (!isNaN(this.$route.params.section) ? parseInt(this.$route.params.section) : 0),
      new_item: null,
      new_items_active: false,
      current_questions: {},
      loading: false,
      selectedItem: "Select an existing item..."
    }
  },
  computed: {
    jobId () {
      return this.$route.params.id;
    },
  },
  updated () {
    this.itemId = null;
    this.sectionId = (!isNaN(this.$route.params.section) ? parseInt(this.$route.params.section) : 0);
    this.new_item = null;
    this.new_items_active = false;
    this.current_questions = {};
    this.selectedItem = "Select an existing item..."
  },
  methods: {
    async itemModal () {
      const itemSelect = await modalController.create({
        component: ItemSelect,
        cssClass: 'itemselect-modal',
        componentProps: {
          items: Object.values(this.items).map(item => Object.assign(
            {},
            item,
            {
              section: {
                name: this.section.name,
                id: this.section.id,
              }
            }
          )),
          sectionId: this.sectionId
        },
      });

      itemSelect.onDidDismiss()
        .then((data) => {
          if (data.data) {
            this.itemName(JSON.parse(JSON.stringify(data.data)));
          }
        });

      return await itemSelect.present();
    },
    async itemName (item) {
      const itemName = await modalController.create({
        component: ItemName,
        cssClass: 'itemname-modal',
        componentProps: {
          title: item.name,
        },
      });

      itemName.onDidDismiss()
        .then((data) => {
          if (data.data !== undefined) {
            this.new_item = item;
            this.new_item.name = data.data;
            this.new_items_active = true
            if (item.name) {
              this.selectedItem = item.name
            }
            this.new_item.questions = Object.values(this.new_item.questions);
            if (this.new_item.questions && this.new_item.questions.length > 0) {
              this.new_item.questions.forEach((question) => {
                this.current_questions[question.id] = question.title;
              })
            }
          }
        });

      return await itemName.present();
    },
    async triggerAlert (header, message) {
      const alert = await alertController
        .create({
          cssClass: 'noitem-alert',
          header: header,
          message: message,
          buttons: ['OK'],
        });
      return alert.present();
    },
    // footer triggers
    addItem (option) {
      if (this.new_item !== null && this.new_items_active) {
        if (this.sectionId != null) {
          this.loading = true;
          let item_to_add = JSON.parse(JSON.stringify(this.new_item));

          item_to_add.id = this.getNextNegativeId(this.items)
          delete (item_to_add['section']);
          delete (item_to_add['component']);
          delete (item_to_add['canSubmit']);
          item_to_add.app_added = true;

          let questions = {};
          let answer_note_list = {};

          let question_id_map = {};
          let question_copies = {};

          item_to_add.questions.forEach((current_question) => {
            let original_id = current_question.id;
            current_question.old_id = original_id;

            answer_note_list[current_question.answer.id] = current_question.answer;
            current_question.id = this.getNextNegativeId([].concat(Object.values(questions), item_to_add.questions));

            question_id_map[original_id] = current_question.id;
            question_copies[original_id] = current_question;

            current_question.answer.answer = null;
            current_question.answer.date_answered = null;
            current_question.answer.answered_by = null;

            current_question.answer_note.type = null;
            current_question.answer_note.text = null;
            current_question.answer_note.video = null;
            current_question.answer_note.video = [];

            questions[current_question.id] = current_question;
          })

          for (let question_prop in questions) {
            let current_question = questions[question_prop];
            if (current_question['conditional']) {
              // get copied question
              let q = question_copies[current_question.old_id];
              let new_conditional = [];
              new_conditional[0] = question_id_map[q.conditional[0]];
              new_conditional[1] = q.conditional[1];
              new_conditional[2] = q.conditional[2];
              q.conditional = new_conditional;
            }
          }

          item_to_add.questions = questions;

          this.sections[this.sectionId].items[item_to_add.id] = item_to_add;

          this.new_items_active = false;
          this.current_questions = {};
          this.itemId = 0;
          let oldSectionID = this.sectionId;
          this.sectionId = 0;
          this.new_item = null;
          this.loading = false;

          if (option == "new") {
            this.$router.push('/job/'+this.jobId + '/section/' + oldSectionID)
          }
        } else {
          this.triggerAlert('No section selected', 'You must select an existing section to assign the new item too.')
        }
      } else {
        this.triggerAlert('No item selected', 'You must select an existing item to add a new item.')
      }
    },
    cancel () {
      this.$router.back()
    },
    async conditionalWarning () {
      const conditionalWarning = await alertController.create({
        cssClass: 'conditional-warning',
        header: 'Warning!',
        message: 'There are other questions that depend on this question conditionally. Deleting this question will remove the conditions from these questions.',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            id: 'cancel-button',
            handler: () => {
              conditionalWarning.dismiss(false)
              return false;
            }
          }, {
            text: 'Delete',
            role: 'continue',
            id: 'confirm-button',
            handler: () => {
              conditionalWarning.dismiss(true)
              return false;
            }
          }
        ]
      });

      await conditionalWarning.present();

      let interaction;
      await conditionalWarning.onDidDismiss().then((data) => {
        interaction = data;
      })
      return interaction;
    },
    async removeQuestion (id) {
      let affected_questions = {};
      let indexToDelete = -1;
      let toDelete = true;

      this.new_item.questions.forEach((question, index) => {
        let condition = question.conditional;
        if (condition) {
          if (id == condition[0]) {
            // question is conditional on one being deleted
            affected_questions[index] = question
          }
        }

        if (question.id == id) {
          indexToDelete = index;
        }
      })

      if (Object.keys(affected_questions).length > 0) {
        // warn that questions will be affected
        await this.conditionalWarning().then((res) => {
          toDelete = (res.data === true);
        })
      }
      if (toDelete && indexToDelete != -1) {
        for (const prop in affected_questions) {
          this.new_item.questions[prop].conditional = null;
        }

        this.new_item.questions.splice(indexToDelete, 1)
      }
    },
    removeImage (item, photo) {
      let indexToDelete = -1;
      for (let i = 0; i < item.images.length; i++) {
        let current = item.images[i];
        if (current.id == photo.id) {
          // needs deleting
          indexToDelete = i;
        }
      }
      if (indexToDelete != -1) {
        item.images.splice(indexToDelete, 1)
      }
    },
    async takePicture () {
      try {
        const image = await Camera.getPhoto({
          resultType: CameraResultType.Base64,
          source: CameraSource.Camera,
          quality: 40,
          allowEditing: true,
          correctOrientation: true,
          height: 800,
          width: 800
        });

        const fileName = new Date().getTime() + '.jpeg';
        const savedFileImage = {
          filepath: fileName,
          base64: "data:image/jpeg;base64," + image.base64String,
        };

        this.new_item.images.push(savedFileImage)
      } catch (e) {
        console.log("error", e);
      }
    },
  }
}
</script>

<style scoped lang="scss">
h1, h2 {
  text-align: center;
  font-size: 20px;
}

.button-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.item-card {
  border-radius: 0px;
  margin-bottom: 40px;
  --background: #ffffff;
  margin-left: 30px;
  margin-right: 30px;

  .card-section {

    &.question-section {
      margin-top: 15px;

      .section-content {
        margin-bottom: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 10px 10px 15px;
      }

      .question-type {
        margin-bottom: 20px;
        margin-top: 10px;
        text-transform: capitalize;
      }

      .title {
        font-weight: 800;
      }
    }

    .section-title {
      font-weight: 800;
      font-size: 14px;
      font-family: 'Raleway', sans-serif;
    }
    .section-content {
      box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
      margin-bottom: 20px;
      margin-top: 5px;
    }
  }
}

ion-select {
  --padding-start: 10px;
  --placeholder-color: #373636;
  --placeholder-opacity: 1;
  --color: #373636;
}

ion-textarea {
  --padding-start: 10px;
  --placeholder-color: #373636;
  --placeholder-opacity: 1;
  --color: #373636;
}

.question-heading {
  margin-top: 30px;
}

ion-toolbar {
  --min-height: 50px;
  --padding-bottom: 0px;
  --padding-end: 0px;
  --padding-start: 0px;
  --padding-top: 0px;
  ion-label {
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    font-size: 12px;
  }
}

ion-tab-button {
  svg {
    color: #DB9123;
  }
  --background: #172943;
  color: #ffffff;
  text-transform: uppercase;
}

ion-grid {
  --ion-grid-padding: 0px;
}

.taken-photos {
  margin-bottom: 20px;
  .orange-circle {
    height: 25px;
    width: 25px;
    padding: 2px;
    margin: auto;
  }
}

ion-input {
  --padding-start: 10px;
}

.select-item {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.no-question {
  text-align: center;
  margin-top: 10px;
}

.image-container {
  ion-img {
    height: 60px;
    width: auto;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .icon {
    margin-top: 5px;
    color: #172943;
  }
}
</style>
