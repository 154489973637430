<template>
  <ion-content color="white" fullscreen class="ion-padding" scroll-y="true">
    <div>
      <div class="close-modal" @click="closeModal">
        <uil-multiply size="20px" />
      </div>

      <div class="modal-section">
        <h1>Add an item</h1>
        <ion-searchbar v-model="currentSearch" animated placeholder="Search for an item" />

        <template v-if="sectionResults.length">
          <ion-list-header>
            <ion-label>Items in the current section</ion-label>
          </ion-list-header>
          <ion-list lines="full">
            <ion-item v-for="item in sectionResults" :key="item.id">
              <ion-label>
                <div class="item-result" @click="selectItem(item)">
                  <div class="item-result-left">
                    <h2>{{ item.name }}</h2>
                    <h3>Section: {{ item.section.name }}</h3>
                  </div>
                  <div class="item-result-right">
                    <template v-if="item.images && item.images.length">
                      <indexed-db-img :idb-key="item.images[0].id" class="item-image" />
                    </template>
                  </div>
                </div>
              </ion-label>
            </ion-item>
          </ion-list>
        </template>

        <template v-if="searchResults.length">
          <ion-list-header>
            <ion-label>Other Sections</ion-label>
          </ion-list-header>
          <ion-list lines="full">
            <ion-item v-for="item in searchResults" :key="item.id">
              <ion-label>
                <div class="item-result" @click="selectItem(item)">
                  <div class="item-result-left">
                    <h2>{{ item.name }}</h2>
                    <h3>Section: {{ item.section.name }}</h3>
                  </div>
                  <div class="item-result-right">
                    <template v-if="item.images.length > 0">
                      <indexed-db-img :idb-key="item.images[0].id" class="item-image" />
                    </template>
                  </div>
                </div>
              </ion-label>
            </ion-item>
          </ion-list>
        </template>
      </div>
    </div>
  </ion-content>
</template>

<script>
import {IonContent, IonItem, IonList, IonListHeader, IonSearchbar, modalController, IonLabel} from '@ionic/vue';
import {UilMultiply} from '@iconscout/vue-unicons'
import IndexedDbImg from "@/components/IndexedDbImg";

export default {
  components: {
    IndexedDbImg,
    IonContent,
    UilMultiply,
    IonSearchbar,
    IonList,
    IonItem,
    IonListHeader,
    IonLabel,
  },
  props: {
    items: {
      type: [Object, Array],
      required: true,
    },
    sectionId: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data () {
    return {
      currentSearch: "",
    }
  },
  computed: {
    itemsArray () {
      return Array.isArray(this.items)
        ? this.items
        : Object.values(this.items);
    },
    searchResults () {
      return this.itemsArray.filter(item => {
        if (item.section.id === this.sectionId) {
          return false;
        } else {
          if (this.currentSearch && this.currentSearch.trim() !== '') {
            return item.name.toLowerCase().includes(this.currentSearch.toLowerCase());
          }
          return true;
        }
      });
    },
    sectionResults () {
      return this.itemsArray.filter(item => {
        if (item.section.id !== this.sectionId) {
          return false;
        } else {
          if (this.currentSearch && this.currentSearch.trim() !== '') {
            return item.name.toLowerCase().includes(this.currentSearch.toLowerCase());
          }
          return true;
        }
      });
    },
  },
  methods: {
    closeModal () {
      modalController.dismiss();
    },
    selectItem (item) {
      modalController.dismiss(item)
    },
  },
};
</script>

<style scoped lang="scss">
.modal-section {
  h1 {
    margin-top: 0px;
    padding: 0px 12px;
  }
}

.close-modal {
  top: 16px;
}

ion-searchbar {
  --border-radius: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

ion-list {
  margin: 0px 12px;
  ion-item {
    --background: #ffffff;
    --border-width: 0.55px 0 0px 0 !important;
    --padding-start: 12px;
    --padding-end: 12px;
    --inner-padding-end: 0px;
  }
}

ion-label {
  padding-left: 0px;
  margin-right: 0px;
}

.item-result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .item-image {
    width: 50px;
    height: auto;
  }
  h2 {
    font-weight: 600;

  }
}

ion-list-header {
  padding: 0px 12px;
  ion-label {
    padding-left: 0px;
    text-transform: uppercase;
    color: #373636;
    font-family: 'Raleway', sans-serif;
    font-size:  18px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
</style>
