import {mapGetters} from "vuex";
import { v4 as uuidv4 } from 'uuid';

/**
 * Component must provide these properties (as props/data/computed/whatever) if you want this.job/section/item/etc to be computed:
 * this.jobId, this.sectionId, this.itemId
 */
export default {
  data: () => ({
    jobs: null,
  }),
  computed: {
    ...mapGetters({
      vuexJobs: 'brightchecker/jobs',
    }),
    job () {
      return (this.jobId && this.jobs) ? this.jobs[this.jobId] : undefined;
    },
    asset () {
      return this.job ? this.job.asset : undefined;
    },
    sections () {
      return (this.job && this.job.survey) ? this.job.survey.sections : undefined;
    },
    section () {
      return (this.sectionId && this.sections) ? this.sections[this.sectionId] : undefined;
    },
    items () {
      return this.section ? this.section.items : undefined;
    },
    item () {
      return (this.itemId && this.items) ? this.items[this.itemId] : undefined;
    },
    vuexJobsJson () {
      // To monitor changes. Directly watching an object often leads to oldValue/newValue often being identical because they refer to the same in-memory object.
      // @todo: Replace with string hash
      return JSON.stringify(this.vuexJobs);
    },
    jobsJson () {
      // To monitor changes. Directly watching an object often leads to oldValue/newValue often being identical because they refer to the same in-memory object.
      // @todo: Replace with string hash
      return JSON.stringify(this.jobs);
    },
    /**
     * @todo: Split into shorter methods?
     */
    jobsProgress () {
      if (this.vuexJobs) {
        const questionStats = () => ({
            optionalQuestions: { questionCount: 0, answeredCount: 0, unAnsweredCount: 0 },
            recommendedQuestions: { questionCount: 0, answeredCount: 0, unAnsweredCount: 0 },
            requiredQuestions: { questionCount: 0, answeredCount: 0, unAnsweredCount: 0 },
            allQuestions: { questionCount: 0, answeredCount: 0, unAnsweredCount: 0 },
            progressPercent: 0.00,
            canSubmit: false,
          }),
          calculateProgressPercent = (statGroup) => {
            statGroup.progressPercent = statGroup.requiredQuestions.questionCount
              ? 100 * (statGroup.requiredQuestions.answeredCount / statGroup.requiredQuestions.questionCount)
              : 0.00;

            statGroup.canSubmit = (statGroup.progressPercent === 100);
          },
          progress = { byJob: {}, bySection: {}, byItem: {} },
          getStatKeysForQuestion = (question) => {
            const statKeysByRequiredValue = {
                2: 'optionalQuestions',
                0: 'recommendedQuestions',
                1: 'requiredQuestions',
              },
              questionStatKeys = ['allQuestions'];

            if (statKeysByRequiredValue[question.required]) {
              questionStatKeys.push(statKeysByRequiredValue[question.required]);
            }
            return questionStatKeys;
          };

        Object.values(this.vuexJobs).forEach(job => {
          progress.byJob[job.id] = questionStats();
          Object.values(job.survey.sections).forEach(section => {
            progress.bySection[section.id] = questionStats();
            Object.values(section.items).forEach(item => {
              progress.byItem[item.id] = questionStats();
              Object.values(item.questions).forEach(question => {
                const questionIsAnswered = ![undefined, null, ""].includes(question.answer.answer);
                [ progress.byJob[job.id], progress.bySection[section.id], progress.byItem[item.id] ]
                  .forEach(statGroup => {
                    getStatKeysForQuestion(question).forEach(statKey => {
                      statGroup[statKey].questionCount++;
                      if (questionIsAnswered) {
                        statGroup[statKey].answeredCount++;
                      } else {
                        statGroup[statKey].unAnsweredCount++;
                      }
                    });
                  });
              })
              calculateProgressPercent(progress.byItem[item.id]);
            })
            calculateProgressPercent(progress.bySection[section.id]);
          });
          calculateProgressPercent(progress.byJob[job.id]);
        });
        return progress;
      }
      return undefined;
    },
  },
  watch: {
    vuexJobsJson: {
      immediate: true,
      handler (newValue) {
        if (newValue !== this.jobsJson) { // Avoids infinite loops
          console.log("mutates-jobs.watch.vuexJobsJson.handler() overwriting `this.jobs`");
          this.jobs = JSON.parse(newValue); // Destructive clone
        }
      }
    },
    jobsJson (newValue, oldValue) {
      if (oldValue !== 'null' && newValue !== oldValue) { // Avoids infinite loops
        console.log("mutates-jobs.watch.jobsJson() committing `brightchecker/SET_JOBS`");
        this.$store.commit("brightchecker/SET_JOBS", this.jobs);
      }
    },
  },
  methods: {
    getNextNegativeId () {
      return uuidv4();
    },
    circleStyle (section) {
      let progressPercent = this.jobsProgress.bySection[section.id].progressPercent,
        circumference = 30 * 2 * Math.PI,
        dashOffset = circumference - (progressPercent / 100 * circumference),
        color = "#B41F1F";

      if (progressPercent < 75) {
        color = "#F7B900";
      } else if (progressPercent < 25) {
        color = "#B41F1F";
      } else {
        color = "#1D9F67";
      }

      return {
        "stroke-dasharray": `${circumference}, ${circumference}`,
        "stroke-dashoffset": dashOffset,
        "stroke": color
      }
    },
  },
}
