<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>Set Item Name</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content color="white" class="ion-padding" scroll-y="false">
    <div>
      <ion-input placeholder="Enter item name" v-model="inputData" />
    </div>
    <div class="btn-container">
      <ion-button class="" @click="saveModal(inputData)">Save</ion-button>
    </div>
  </ion-content>
</template>

<script>
import {
  IonButton,
  IonContent,
  IonHeader,
  IonInput,
  IonTitle,
  IonToolbar,
  modalController,
} from '@ionic/vue';

export default {
  components: {
    IonButton,
    IonContent,
    IonHeader,
    IonInput,
    IonTitle,
    IonToolbar,
  },
  props: {
    title: {
      type: String,
      default: 'item Name',
    },
  },
  data () {
    return {
      inputData: this.title,
    }
  },
  methods: {
    saveModal (name) {
      modalController.dismiss(name);
    },
    closeModal (close) {
      modalController.dismiss(close);
    },
  },
};
</script>

<style scoped lang="scss">
ion-input {
  border: 1px solid #373636;
  --placeholder-color: #373636;
  --color: #373636;
  --padding-bottom: 10px;
  --padding-top: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
}

ion-button {
  margin-top: 10px;
  margin-left: 0px;
}

.btn-container {
  text-align: center;
}
</style>
