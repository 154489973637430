<template>
  <ion-img ref="image" v-if="showImage" />
</template>

<script>
import {IonImg} from "@ionic/vue";
import localForage from "localforage";

export default {
  components: {
    IonImg,
  },
  props: {
    idbKey: [String, Number],
  },
  computed: {
    showImage () {
      // Can't simply check if `this.idbKey` is falsy. Because there are falsy values that might be a real key.
      // @see: https://developer.mozilla.org/en-US/docs/Glossary/Falsy
      return (this.idbKey !== undefined && this.idbKey !== null && this.idbKey !== "");
    },
  },
  watch: {
    idbKey (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.nativelySetImage();
      }
    },
  },
  methods: {
    nativelySetImage () {
      if (this.showImage) {
        localForage.getItem(this.idbKey.toString()).then((value) => {
          let renderAttempts = 0,
            maxRenderAttempts = 12,
            attemptTimeoutDuration = 250,
            render = () => {
              renderAttempts++;
              if (this.$refs.image) {
                this.$refs.image.$el.src = value ? value.base64 : null
              } else if (renderAttempts < maxRenderAttempts) {
                setTimeout(render, attemptTimeoutDuration)
              }
            };

          render();
        });
      }
    },
  },
  mounted () {
    this.nativelySetImage();
  },
};
</script>
